import { Card, Checkbox, Col, Radio, Row } from 'antd';
import { RadioChangeEvent } from 'antd/lib/radio';
import Title from 'antd/lib/typography/Title';
import React, { Fragment } from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { Product, ProductDetail } from '../../utils/types';

interface IProps {
    products: Product[];
    productSelected?: Product;
    queryProductId: string | null;
    selectProduct: (product: Product) => void;
    selectProductDetail: (detail: ProductDetail) => void;
    selectProductDetailLevel: (level: string) => void;
};

interface State {
    value: number;
    levelValue: number;
    // checkboxDetail: boolean;

}

type Props = IProps & WrappedComponentProps;

class ProductsTab extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            value: 0,
            levelValue: 0,
            // checkboxDetail: false,
        };
    }

    componentDidMount() {
        const { products, queryProductId } = this.props;

        if (queryProductId != null && !isNaN(parseInt(queryProductId)) && parseInt(queryProductId) >= 0) {
            let product = products.find(p => p.id === parseInt(queryProductId));
            if (product !== undefined) {
                if (!product.disabled) {
                    // this.props.selectProduct(products[parseInt(queryProductId) - 1]);
                    this.props.selectProduct(product);
                } else {
                    document.location.href = "/";
                }
            } else {
                document.location.href = "/";
            }
        }
    }


    // renderParticipant = (participant: Participant): React.ReactNode => {
    //     return <ParticipantItem cancelParticipant={this.cancelParticipant} addParticipant={this.addParticipant} participant={participant} participantsTabDisabled={this.state.participantsTabDisabled} productsTabDisabled={this.state.productsTabDisabled} goToTab={this.props.goToTab}/>;
    // }

    onChange = (e: RadioChangeEvent) => {
        this.setState({ value: e.target.value, levelValue: 0 }, () => {
            if (this.props.productSelected?.details[this.state.value]) {
                this.props.selectProductDetail(this.props.productSelected?.details[this.state.value]);
            }
        });
    };

    onChangeLevel = (e: RadioChangeEvent) => {
        this.setState({ levelValue: e.target.value }, () => {
            if (this.props.productSelected && this.props.productSelected.details[0].level && this.props.productSelected.details[0].level.length > 0) {
                this.props.selectProductDetailLevel(this.props.productSelected.details[this.state.value].level![this.state.levelValue]);
            }
        });
    };

    optionProductDetail = (value: boolean) => {
        let newProductSelected: ProductDetail = {
            description: this.props.productSelected ? this.props.productSelected.details[this.state.value].description : "error",
            descriptionAdult: this.props.productSelected ? this.props.productSelected.details[this.state.value].descriptionAdult : "error",
            descriptionTeenager: this.props.productSelected ? this.props.productSelected.details[this.state.value].descriptionTeenager : "error",
            descriptionJunior: this.props.productSelected ? this.props.productSelected.details[this.state.value].descriptionJunior : "error",
            descriptionFree: this.props.productSelected ? this.props.productSelected.details[this.state.value].descriptionFree : "error",
            price: this.props.productSelected ? this.props.productSelected.details[this.state.value].price : -1,
            priceTeenager: this.props.productSelected ? this.props.productSelected.details[this.state.value].priceTeenager : -1,
            priceJunior: this.props.productSelected ? this.props.productSelected.details[this.state.value].priceJunior : -1,
            discountHP: this.props.productSelected ? this.props.productSelected.details[this.state.value].discountHP : -1,
        };
        // this.props.productSelected && this.props.productSelected.details[this.state.value] && this.props.productSelected.details[this.state.value].level && this.props.productSelected.details[this.state.value].level![this.state.levelValue] && (newProductSelected.level = [this.props.productSelected.details[this.state.value].level![this.state.levelValue]])
        if (newProductSelected && value) {
            newProductSelected.option = this.props.productSelected?.details[this.state.value].option;
            newProductSelected.optionPrice = this.props.productSelected?.details[this.state.value].optionPrice;
        } else if (newProductSelected) {
            newProductSelected.option = undefined;
            newProductSelected.optionPrice = undefined;
        }
        newProductSelected && this.props.selectProductDetail(newProductSelected);
    };

    htmlDecode = (input: string) => {
        var e = document.createElement('div');
        e.innerHTML = input;
        return e.childNodes.length === 0 ? "" : e.childNodes[0].nodeValue !== null ? e.childNodes[0].nodeValue : "";
    };

    render() {
        const { formatMessage } = this.props.intl;
        const { Meta } = Card;
        const { productSelected } = this.props;
        const { value, levelValue } = this.state;
        let index = 0;
        let indexLevel = 0;

        return (
            <Fragment>
                <Row gutter={[20, 20]}>
                    {
                        productSelected ?
                            <Fragment>
                                <Col xs={24} sm={24} md={10} lg={8} xl={8}>
                                    <img style={productSelected.type === "subscription" ? { maxWidth: '100%', height: '100%', objectFit: 'cover' } : { maxWidth: '100%' }} alt={productSelected.name} src={productSelected.coverUrl} />
                                </Col>
                                <Col xs={24} sm={24} md={14} lg={16} xl={16} style={productSelected.type === "subscription" ? { backgroundColor: '#ffffffc1' } : {}}>
                                    <Title level={2}>{productSelected.name}</Title>
                                    <p className='not-bold' style={{ fontStyle: 'italic', fontSize: '95%' }}>{productSelected.description}</p>
                                    <div className="__hr-line" style={productSelected.type === "subscription" ? { backgroundColor: 'lightgray' } : {}}></div>
                                    {
                                        productSelected.type === "subscription" ?
                                            <>
                                                {
                                                    productSelected.details.map((detail) => {
                                                        return detail.descriptionHtml;
                                                    })
                                                }
                                            </>
                                            :
                                            <>
                                                <Title level={3}>{formatMessage({ id: 'product.courses' })}</Title>
                                                <Radio.Group onChange={this.onChange} value={value}>
                                                    {
                                                        productSelected.details.map((detail) => {
                                                            return (
                                                                <Radio style={{ wordWrap: 'break-word', display: 'block' }} key={`productSelected-detail-${index}`} value={index++}>
                                                                    {`${detail.description} (${detail.discountHP ? `FR. ${detail.price - detail.discountHP}.- ${detail.discountHP ? 'pour résidents du Haut Plateau' : ''} / ` : ''}FR. ${detail.price}.- ${detail.discountHP ? 'pour non résidents du Haut Plateau' : ''})`}
                                                                </Radio>
                                                            );
                                                        })
                                                    }
                                                </Radio.Group>
                                            </>
                                    }

                                    {productSelected.details[value].level && <Title style={{ marginTop: '10px' }} level={3}>{formatMessage({ id: 'product.levels' })}</Title>}
                                    <Radio.Group value={levelValue} onChange={this.onChangeLevel}>
                                        {
                                            productSelected.details[value].level?.map((detail) => {
                                                return (
                                                    <Radio key={`productSelected-detail-${indexLevel}`} value={indexLevel++}>
                                                        {detail}
                                                    </Radio>
                                                );
                                            })
                                        }
                                    </Radio.Group>
                                    {
                                        productSelected?.details[this.state.value] && productSelected?.details[this.state.value].option &&
                                        <Fragment>
                                            <Title style={{ marginTop: '20px' }} level={3}>{formatMessage({ id: 'product.options' })}</Title>
                                            <Checkbox onChange={(e) => this.optionProductDetail(e.target.checked)}>{productSelected?.details[this.state.value].option + " (FR. " + productSelected?.details[this.state.value].optionPrice + ".-)"}</Checkbox>
                                        </Fragment>
                                    }
                                </Col>
                            </Fragment>
                            :
                            <>
                                {/* <Col xs={24}>
                                    <p style={{textAlign: 'center', marginTop: '15px', fontWeight: 'bold'}}>Inscriptions clôturées -  en cas de questions, veuillez vous adresser à <a href="mailto:president@skiclubcransmontana.ch">Alessio Gioli</a></p>
                                </Col> */}
                                {
                                    this.props.products.filter(p => p.visible).map((product) => {
                                        return (
                                            <Col key={`product${product.name}`} xs={24} sm={12} md={8} lg={8} xl={6}>
                                                <Card
                                                    style={product.disabled ? { filter: 'grayscale(1)', cursor: 'not-allowed', minHeight: '410px' } : { minHeight: '410px' }}
                                                    onClick={() => { if (!product.disabled) { this.setState({ value: 0, levelValue: 0 }); this.props.selectProduct(product); } }}
                                                    hoverable
                                                    cover={<img alt={product.name} src={product.coverUrl} />}
                                                >
                                                    <Meta title={product.name} description={<>{product.description}</>} />
                                                </Card>
                                            </Col>
                                        );

                                    })
                                }
                            </>
                    }
                </Row>
            </Fragment>
        );
    }
}

export default injectIntl(ProductsTab);