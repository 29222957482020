import { Card, Row, Col } from 'antd';
import Title from 'antd/lib/typography/Title';
import moment from 'moment';
import React, { Fragment, ReactNode } from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { Country, Customer, Participant, Product, ProductDetail } from '../../utils/types';
import { CheckSquareOutlined, CloseSquareOutlined, EditOutlined } from '@ant-design/icons';
import { sizeJacket } from './registration';
import { toInteger } from 'lodash';

interface IProps {
    productSelected?: Product;
    countries: Country[] | undefined;
    customer?: Customer;
    participants: Participant[];
    detailSelected?: ProductDetail;
    detailLevelSelected?: string;
    invoiceToHtml: (content: JSX.Element) => void;
    changeTab: (tabIndex: string) => void;
};

interface State {
    element?: ReactNode;
}

type Props = IProps & WrappedComponentProps;

class CartTab extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
        };
    }

    componentDidMount() {
        this.checkout();
    }

    componentDidUpdate(prevProps: Props) {
        if (this.state.element === undefined || prevProps !== this.props) {
            this.checkout();
        }
    }

    checkout = () => {
        const { formatMessage } = this.props.intl;
        const GENDER: {label: string, value: number}[]= [
            { value: 1, label: formatMessage({ id: "participant.gender.male" }) },
            { value: 2, label: formatMessage({ id: "participant.gender.female" }) },
            { value: 3, label: formatMessage({ id: "participant.gender.other" }) },
        ];

        const { productSelected, customer, participants, detailSelected, detailLevelSelected } = this.props;
        const countries = this.props.countries?.map(c=>({label: c.translations[0].name, value: c.id}));
        if (customer && detailSelected) {
            let participantsAdult: Participant[] = [];
            let participantsTeenager: Participant[] = [];
            let participantsJunior: Participant[] = [];
            let participantsJuniorFree: Participant[] = [];
            let finalPrice: number = 0;
            if (productSelected?.type === 'subscription') {
                if (customer.domiciledAtCMAndL !== undefined && customer.domiciledAtCMAndL) {
                    participantsAdult = participants.filter(p => p.birthday && p.birthday.isSameOrBefore(moment("30/05/2003", 'DD/MM/YYYY'), "days"));
                    participantsTeenager = participants.filter(p => p.birthday && p.birthday.isBetween(moment("31/05/2003", 'DD/MM/YYYY'), moment("30/05/2004", 'DD/MM/YYYY'), "days", "[]"));
                    participantsJuniorFree = participants.filter(p => p.birthday && p.birthday.isSameOrAfter(moment("31/05/2004", 'DD/MM/YYYY'), "days"));
                } else if (customer.domiciledAtI !== undefined && customer.domiciledAtI) {
                    participantsAdult = participants.filter(p => p.birthday && p.birthday.isSameOrBefore(moment("30/05/2003", 'DD/MM/YYYY'), "days"));
                    participantsTeenager = participants.filter(p => p.birthday && p.birthday.isBetween(moment("31/05/2003", 'DD/MM/YYYY'), moment("30/05/2004", 'DD/MM/YYYY'), "days", "[]"));
                    participantsJuniorFree = participants.filter(p => p.birthday && p.birthday.isSameOrAfter(moment("02/07/2004", 'DD/MM/YYYY'), "days"));
                } else {
                    participantsAdult = participants.filter(p => p.birthday && p.birthday.isSameOrBefore(moment("30/05/2003", 'DD/MM/YYYY'), "days"));
                    participantsTeenager = participants.filter(p => p.birthday && p.birthday.isBetween(moment("31/05/2003", 'DD/MM/YYYY'), moment("30/05/2008", 'DD/MM/YYYY'), "days", "[]"));
                    participantsJunior = participants.filter(p => p.birthday && p.birthday.isBetween(moment("31/05/2008", 'DD/MM/YYYY'), moment("30/05/2015", 'DD/MM/YYYY'), "days", "[]"));
                    participantsJuniorFree = participants.filter(p => p.birthday && p.birthday.isSameOrAfter(moment("31/05/2015", 'DD/MM/YYYY'), "days"));
                }
                finalPrice = participantsAdult.length * detailSelected.price + participantsTeenager.length * (detailSelected.priceTeenager ?? 0) + participantsJunior.length * (detailSelected.priceJunior ?? 0);
            }
            let content = (
                <Fragment>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <Title level={3}>{formatMessage({ id: 'registration.personalData' })}</Title>
                        <Card title={customer.firstName + " " + customer.lastName} bordered={false} extra={<EditOutlined key="edit" onClick={() => this.props.changeTab("2")} />} >
                            <span><span style={{ fontWeight: 'bold' }}>{formatMessage({ id: 'registration.address' })} : </span>{customer.address + ", " + customer.npa + " " + customer.city}</span>
                            <span style={{ marginLeft: '10px' }}><span style={{ fontWeight: 'bold' }}>{formatMessage({ id: 'registration.email' })} : </span>{customer.email}</span>
                            <span style={{ marginLeft: '10px' }}><span style={{ fontWeight: 'bold' }}>{formatMessage({ id: 'registration.phone' })} : </span>{customer.phone}</span>
                            <br />
                            <br />
                            <span style={{ fontWeight: 'bold' }}>{"Options:"} : </span>
                            <br />
                            {
                                productSelected?.type !== 'subscription' ?
                                    <>
                                        <span>{customer.lastYear ? <CheckSquareOutlined /> : <CloseSquareOutlined />} {formatMessage({ id: 'registration.lastYear' })}</span>
                                        <br />
                                        <span>{customer.domiciledAtHP ? <CheckSquareOutlined /> : <CloseSquareOutlined />} {formatMessage({ id: 'registration.domiciledAtHP' })}</span>
                                        <br />
                                        <span>{customer.alreadyPaidFee ? <CheckSquareOutlined /> : <CloseSquareOutlined />} {formatMessage({ id: 'registration.alreadyPaidFee' })}</span>
                                        <br />
                                        <span>{customer.photo ? <CheckSquareOutlined /> : <CloseSquareOutlined />} {formatMessage({ id: 'registration.photo' })}</span>
                                    </>
                                    :
                                    <>
                                        <span>{customer.domiciledAtCMAndL ? <CheckSquareOutlined /> : <CloseSquareOutlined />} {formatMessage({ id: 'registration.domiciledAtCMAndL' })}</span>
                                        <br />
                                        <span>{customer.domiciledAtI ? <CheckSquareOutlined /> : <CloseSquareOutlined />} {formatMessage({ id: 'registration.domiciledAtI' })}</span>
                                    </>
                            }
                        </Card>
                    </Col>


                    <Title style={{ marginTop: '18px' }} level={3}>{formatMessage({ id: 'participant.title' })}</Title>
                    <Row gutter={[8, 8]}>
                        {
                            participants.map((participant, idx) => {
                                let price = -1;
                                if (productSelected?.type === 'subscription') {
                                    if (customer?.domiciledAtCMAndL !== undefined && customer?.domiciledAtCMAndL) {
                                        if (participant.birthday && participant.birthday.isSameOrBefore(moment("30/05/2003", 'DD/MM/YYYY'), "days")) {
                                            price = detailSelected ? detailSelected.price : 0;
                                        } else if (participant.birthday && participant.birthday.isBetween(moment("31/05/2003", 'DD/MM/YYYY'), moment("30/05/2004", 'DD/MM/YYYY'), "days", "[]")) {
                                            price = detailSelected && detailSelected.priceTeenager ? detailSelected.priceTeenager : 0;
                                        } else {
                                            price = 0;
                                        }
                                    } else if (customer?.domiciledAtI !== undefined && customer?.domiciledAtI) {
                                        if (participant.birthday && participant.birthday.isSameOrBefore(moment("30/05/2003", 'DD/MM/YYYY'), "days")) {
                                            price = detailSelected ? detailSelected.price : 0;
                                        } else if (participant.birthday && participant.birthday.isBetween(moment("31/05/2003", 'DD/MM/YYYY'), moment("30/05/2004", 'DD/MM/YYYY'), "days", "[]")) {
                                            price = detailSelected && detailSelected.priceTeenager ? detailSelected.priceTeenager : 0;
                                        } else {
                                            price = 0;
                                        }
                                    } else {
                                        if (participant.birthday && participant.birthday.isSameOrBefore(moment("30/05/2003", 'DD/MM/YYYY'), "days")) {
                                            price = detailSelected ? detailSelected.price : 0;
                                        } else if (participant.birthday && participant.birthday.isBetween(moment("31/05/2003", 'DD/MM/YYYY'), moment("30/05/2008", 'DD/MM/YYYY'), "days", "[]")) {
                                            price = detailSelected && detailSelected.priceTeenager ? detailSelected.priceTeenager : 0;
                                        } else if (participant.birthday && participant.birthday.isBetween(moment("31/05/2008", 'DD/MM/YYYY'), moment("30/05/2015", 'DD/MM/YYYY'), "days", "[]")) {
                                            price = detailSelected && detailSelected.priceJunior ? detailSelected.priceJunior : 0;
                                        } else if (participant.birthday && participant.birthday.isSameOrAfter(moment("31/05/2015", 'DD/MM/YYYY'), "days")) {
                                            price = 0;
                                        } else {
                                            price = 0;
                                        }
                                    }
                                }
                                return (

                                    // <ParticipantItem
                                    //     detailSelected={this.props.detailSelected}
                                    //     productSelected={this.props.productSelected}
                                    //     customer={this.props.customer}
                                    //     key={`participant-${participant.firstName}-${idx}`}
                                    //     id={idx}
                                    //     editParticipants={() => this.props.changeTab("3") }
                                    //     countries={this.props.countries}
                                    //     participant={participant}
                                    //     addParticipant={() => console.log}
                                    //     saveEditParticipant={() => console.log}
                                    //     deleteParticipant={() => console.log}
                                    //     cancelParticipant={() => console.log}/>
                                    <Col key={`participant-${participant.firstName}-${idx}`} xs={24} sm={12} md={8} lg={8} xl={6}>
                                        <Card title={`${participant.firstName} ${participant.lastName}  ${price > -1 ? "(FR " + price + ".-)" : ''}`} bordered={false} extra={<EditOutlined key="edit" onClick={() => this.props.changeTab("3")} />}>
                                            <p><span style={{ fontWeight: 'bold' }}>{formatMessage({ id: 'participant.birthday' })} : </span>{moment(participant.birthday).format('DD/MM/YYYY')}</p>
                                            <p><span style={{ fontWeight: 'bold' }}>{formatMessage({ id: 'participant.gender' })} : </span>{participant && participant.gender != null &&  GENDER.find(gender => gender.value === participant.gender)?.label}</p>
                                            {
                                                participant && participant.avs &&
                                                <p><span style={{ fontWeight: 'bold' }}>{formatMessage({ id: 'participant.avs' })} : </span>{participant.avs}</p>
                                            }
                                            {
                                                participant && participant.country ?
                                                    <p><span style={{ fontWeight: 'bold' }}>{formatMessage({ id: 'participant.country' })} : </span>{countries?.find(c => c.value === participant.country)?.label}</p>
                                                    : null
                                            }
                                            {
                                                participant && participant.sizeJacket ?
                                                    <p><span style={{ fontWeight: 'bold' }}>{formatMessage({ id: 'participant.sizeJacket' })} : </span>{sizeJacket.find(j => j.value === toInteger(participant.sizeJacket))?.label}</p>
                                                    : null
                                            }
                                        </Card>
                                    </Col>
                                );
                            })
                        }
                    </Row>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <Title style={{ marginTop: '14px' }} level={3}>{formatMessage({ id: 'cart.product' })}</Title>
                        <div className="ant-table">
                            <div className="ant-table-container">
                                <div className="ant-table-content">
                                    <table style={{ tableLayout: 'auto' }}>
                                        <thead className="ant-table-thead">
                                            <tr>
                                                <th style={{ fontWeight: 'bold' }} className="ant-table-cell">{formatMessage({ id: 'cart.description' })}</th>
                                                <th style={{ fontWeight: 'bold', textAlign: 'center' }} className="ant-table-cell">{formatMessage({ id: 'cart.shortQuantity' })}</th>
                                                <th style={{ fontWeight: 'bold', textAlign: 'right' }} className="ant-table-cell">{formatMessage({ id: 'cart.unityPrice' })}</th>
                                                <th style={{ fontWeight: 'bold', textAlign: 'right' }} className="ant-table-cell">{formatMessage({ id: 'cart.price' })}</th>
                                            </tr>
                                        </thead>
                                        <tbody className="ant-table-tbody">
                                            {
                                                productSelected?.type !== 'subscription' ?
                                                    <>
                                                        <tr data-row-key="1" className="ant-table-row ant-table-row-level-0">
                                                            <td className="ant-table-cell" width="86%">
                                                                {productSelected?.name + ": " + productSelected?.description}
                                                                <div style={{ fontStyle: 'italic' }}><span style={{ fontWeight: 'bold' }}>{formatMessage({ id: 'cart.details' })}</span>: {detailSelected.description}</div>
                                                                {detailLevelSelected !== undefined && <div style={{ fontStyle: 'italic' }}><span style={{ fontWeight: 'bold' }}>{formatMessage({ id: 'cart.level' })}</span>: {detailLevelSelected}</div>}
                                                            </td>
                                                            <td style={{ textAlign: 'center' }} className="ant-table-cell" width="6%">{participants.length}</td>
                                                            <td style={{ textAlign: 'right' }} className="ant-table-cell" width="8%">{(detailSelected.price ? detailSelected.price : 0)}.-</td>
                                                            <td style={{ textAlign: 'right' }} className="ant-table-cell" width="8%">{(detailSelected.price ? participants.length * detailSelected.price : 0)}.-</td>
                                                        </tr>
                                                        {
                                                            detailSelected.option &&
                                                            <tr data-row-key="3" className="ant-table-row ant-table-row-level-0">
                                                                <td className="ant-table-cell">{detailSelected.option}</td>
                                                                <td style={{ textAlign: 'center' }} className="ant-table-cell">{participants.length}</td>
                                                                <td style={{ textAlign: 'right' }} className="ant-table-cell">{(detailSelected.optionPrice ? detailSelected.optionPrice : 0)}.-</td>
                                                                <td style={{ textAlign: 'right' }} className="ant-table-cell">{(detailSelected.optionPrice ? participants.length * detailSelected.optionPrice : 0)}.-</td>
                                                            </tr>
                                                        }
                                                        {
                                                            !customer.alreadyPaidFee &&
                                                            <tr data-row-key="4" className="ant-table-row ant-table-row-level-0">
                                                                <td className="ant-table-cell">{formatMessage({ id: 'registration.fee' })}</td>
                                                                <td style={{ textAlign: 'center' }} className="ant-table-cell">1</td>
                                                                <td style={{ textAlign: 'right' }} className="ant-table-cell">80.-</td>
                                                                <td style={{ textAlign: 'right' }} className="ant-table-cell">80.-</td>
                                                            </tr>
                                                        }
                                                        {
                                                            detailSelected.discountHP && customer.domiciledAtHP &&
                                                            <tr data-row-key="4" className="ant-table-row ant-table-row-level-0">
                                                                <td style={{ textAlign: 'right', fontWeight: 'bold' }} className="ant-table-cell" colSpan={3}>{formatMessage({ id: 'registration.discountHP' })}</td>
                                                                <td style={{ textAlign: 'right' }} className="ant-table-cell">{(participants.length * detailSelected.discountHP)}.-</td>
                                                            </tr>
                                                        }
                                                    </>
                                                    :
                                                    <>
                                                        {
                                                            participantsAdult.length > 0 &&
                                                            <tr data-row-key="1" className="ant-table-row ant-table-row-level-0">
                                                                <td className="ant-table-cell" width="86%">
                                                                    {productSelected?.name + ": " + productSelected?.description}
                                                                    <div style={{ fontStyle: 'italic' }}><span style={{ fontWeight: 'bold' }}>{formatMessage({ id: 'cart.details' })}</span>: {detailSelected.description}</div>
                                                                    {detailLevelSelected !== undefined && <div style={{ fontStyle: 'italic' }}><span style={{ fontWeight: 'bold' }}>{formatMessage({ id: 'cart.level' })}</span>: {detailLevelSelected}</div>}
                                                                </td>
                                                                <td style={{ textAlign: 'center' }} className="ant-table-cell" width="6%">{participantsAdult.length}</td>
                                                                <td style={{ textAlign: 'right' }} className="ant-table-cell" width="8%">{(detailSelected.price ? detailSelected.price : 0)}.-</td>
                                                                <td style={{ textAlign: 'right' }} className="ant-table-cell" width="8%">{(detailSelected.price ? participantsAdult.length * detailSelected.price : 0)}.-</td>
                                                            </tr>
                                                        }
                                                        {
                                                            participantsJunior.length > 0 &&
                                                            <tr data-row-key="1" className="ant-table-row ant-table-row-level-0">
                                                                <td className="ant-table-cell" width="86%">
                                                                    {productSelected?.name + ": " + productSelected?.description}
                                                                    <div style={{ fontStyle: 'italic' }}><span style={{ fontWeight: 'bold' }}>{formatMessage({ id: 'cart.details' })}</span>: {detailSelected.descriptionJunior}</div>
                                                                    {detailLevelSelected !== undefined && <div style={{ fontStyle: 'italic' }}><span style={{ fontWeight: 'bold' }}>{formatMessage({ id: 'cart.level' })}</span>: {detailLevelSelected}</div>}
                                                                </td>
                                                                <td style={{ textAlign: 'center' }} className="ant-table-cell" width="6%">{participantsJunior.length}</td>
                                                                <td style={{ textAlign: 'right' }} className="ant-table-cell" width="8%">{(detailSelected.priceJunior ? detailSelected.priceJunior : 0)}.-</td>
                                                                <td style={{ textAlign: 'right' }} className="ant-table-cell" width="8%">{(detailSelected.priceJunior ? participantsJunior.length * detailSelected.priceJunior : 0)}.-</td>
                                                            </tr>
                                                        }
                                                        {
                                                            participantsTeenager.length > 0 &&
                                                            <tr data-row-key="1" className="ant-table-row ant-table-row-level-0">
                                                                <td className="ant-table-cell" width="86%">
                                                                    {productSelected?.name + ": " + productSelected?.description}
                                                                    <div style={{ fontStyle: 'italic' }}><span style={{ fontWeight: 'bold' }}>{formatMessage({ id: 'cart.details' })}</span>: {detailSelected.descriptionTeenager}</div>
                                                                    {detailLevelSelected !== undefined && <div style={{ fontStyle: 'italic' }}><span style={{ fontWeight: 'bold' }}>{formatMessage({ id: 'cart.level' })}</span>: {detailLevelSelected}</div>}
                                                                </td>
                                                                <td style={{ textAlign: 'center' }} className="ant-table-cell" width="6%">{participantsTeenager.length}</td>
                                                                <td style={{ textAlign: 'right' }} className="ant-table-cell" width="8%">{(detailSelected.priceTeenager ? detailSelected.priceTeenager : 0)}.-</td>
                                                                <td style={{ textAlign: 'right' }} className="ant-table-cell" width="8%">{(detailSelected.priceTeenager ? participantsTeenager.length * detailSelected.priceTeenager : 0)}.-</td>
                                                            </tr>
                                                        }
                                                        {
                                                            participantsJuniorFree.length > 0 &&
                                                            <tr data-row-key="1" className="ant-table-row ant-table-row-level-0">
                                                                <td className="ant-table-cell" width="86%">
                                                                    {productSelected?.name + ": " + productSelected?.description}
                                                                    <div style={{ fontStyle: 'italic' }}><span style={{ fontWeight: 'bold' }}>{formatMessage({ id: 'cart.details' })}</span>: {detailSelected.descriptionFree}</div>
                                                                    {detailLevelSelected !== undefined && <div style={{ fontStyle: 'italic' }}><span style={{ fontWeight: 'bold' }}>{formatMessage({ id: 'cart.level' })}</span>: {detailLevelSelected}</div>}
                                                                </td>
                                                                <td style={{ textAlign: 'center' }} className="ant-table-cell" width="6%">{participantsJuniorFree.length}</td>
                                                                <td style={{ textAlign: 'right' }} className="ant-table-cell" width="8%">0.-</td>
                                                                <td style={{ textAlign: 'right' }} className="ant-table-cell" width="8%">0.-</td>
                                                            </tr>
                                                        }
                                                    </>
                                            }
                                            <tr data-row-key="4" className="ant-table-row ant-table-row-level-0">
                                                <td style={{ textAlign: 'right', fontWeight: 'bold' }} colSpan={3} className="ant-table-cell">{formatMessage({ id: 'cart.total' })}</td>
                                                {
                                                    productSelected?.type !== 'subscription' ?
                                                        <td style={{ textAlign: 'right' }} className="ant-table-cell">{(customer.alreadyPaidFee ? 0 : 80) + (detailSelected.price ? participants.length * detailSelected.price : 0) + (detailSelected.optionPrice ? participants.length * detailSelected.optionPrice : 0) - ((detailSelected.discountHP && customer.domiciledAtHP) ? participants.length * detailSelected.discountHP : 0)}.-</td>
                                                        :
                                                        <td style={{ textAlign: 'right' }} className="ant-table-cell">{(finalPrice)}.-</td>
                                                }

                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Fragment>
            );


            this.setState({ element: content }, () => this.props.invoiceToHtml(content));

        } else {
            return null;
        }
    };

    render() {
        return this.state.element ? this.state.element : <></>;
    }
}

export default injectIntl(CartTab);