import React from 'react';
import { Input, Button, Checkbox, Col, Row, Tabs, Select, Modal, Popconfirm, Alert } from 'antd';
// import { Typography } from 'antd';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { RouterProps, Participant, Customer, Product, Cart, ProductDetail, RegistrationBobyRequest, PayyoCaptureBobyRequest, Country } from '../../utils/types';
// import Network from '../../utils/network';
// import PaymentModal from './paymentModal';
import Title from 'antd/lib/typography/Title';
import ParticipantsTab from './participantsTab';
import ProductsTab from './productsTab';
import CartTab from './cartTab';
import { UsergroupAddOutlined, UserOutlined, SafetyOutlined, ShoppingOutlined } from '@ant-design/icons';
import Network from '../../utils/network';
import { ACCENTCOLOR, BACKGROUNDCOLOR, CURRENCY, SKICLUBCRANSMONTANAID, SKICLUBCRANSMONTANANAME } from '../../utils/constants';
import moment from 'moment';

import ReactDOMServer from 'react-dom/server';

import PreCompetitionIMG from '../../img/speedies-500.jpg';
import PreCompetitionPlusIMG from '../../img/groupe-competition-ACM-500.jpg';
import SkiFunIMG from '../../img/skifun-500.jpg';
import FreestyleIMG from '../../img/freestyle-500.jpg';
import SkiPourTousIMG from '../../img/skipourtous-500.jpg';
import { withRouter } from '../../utils/withRouter';
// import CompetitionIMG from '../../img/cours-competition.jpg';

interface IProps {
};

interface State {
    countries: Country[] | undefined;
    selectedCountry: Country | undefined;
    activeKey: string;
    participants: Participant[];
    invoiceHtml?: string;
    customer?: Customer;
    products: Product[];
    productSelected?: Product;
    productSelectedNext: boolean;
    detailSelected?: ProductDetail;
    detailLevelSelected?: string;
    participantsTabDisabled: boolean;
    cartTabDisabled: boolean;
    cart?: Cart;
    value?: any;
    participantToEdit?: Participant;
    participantToEditId?: number;
    must1: boolean;
    must2: boolean;

}

export const sizeJacket: {label: string, value: number}[] = [
    {value: 1, label: "116/6ans"},
    {value: 2, label: "128/8ans"},
    {value: 3, label: "140/10ans"},
    {value: 4, label: "152/12ans"},
    {value: 5, label: "164/14ans"},
    {value: 6, label: "176/16ans"},
];

const productsAvailable: Product[] = [
    {
        id: 1,
        name: "Ski Pour Tous",
        description: "Amusement et progression garantis ! Dès 4 ans, plongez dans une aventure inoubliable sur les pistes avec notre programme Ski Pour Tous spécialement conçu pour les enfants!",
        coverUrl: SkiPourTousIMG,
        disabled: false,
        visible: true,
        type: 'product',
        details: [
            {
                description: "8 cours snowboard / en janvier, février (sauf vacances scolaires) et mars les samedis après-midi de 13h30 à 16h (y compris location de la veste).",
                price: 210,
                level: ["Snowboard"],
            },
            {
                description: "8 cours ski / en janvier, février (sauf vacances scolaires) et mars les samedis après-midi de 13h30 à 16h (y compris location de la veste).",
                price: 210,
                level: ["Débutant", "Chasse neige", "Chasse neige tournant", "Virage parallèle", "Très bon skieur"],
            },
            {
                description: "15 cours ski / en janvier, février (sauf vacances scolaires) et mars, les mercredis et samedis après-midi de 13h30 à 16h (y compris location de la veste).",
                price: 280,
                level: ["Débutant", "Chasse neige", "Chasse neige tournant", "Virage parallèle", "Très bon skieur"],
            }
        ],
    },
    {
        id: 4,
        name: "Ski Fun",
        description: "Groupe destiné aux jeunes qui savent skier partout et qui désirent un encadrement et des entrainements réguliers, y compris pendant les vacances scolaires des cantons du VS, VD et GE.",
        coverUrl: SkiFunIMG,
        disabled: false,
        visible: true,
        type: 'product',
        details: [
            {
                description: "Entraînement samedi après-midi durant la saison d’hiver, y compris vacances scolaires",
                price: 650,
                discountHP: 200,
                // option: "Supplément pour les vacances de Noel et Carnaval (canton du Valais)",
                // optionPrice: 150,
            }
        ],
    },
    {
        id: 5,
        name: "Freestyle",
        description: "Que ce soit en Snowboard ou en Freeski, cette offre s’adresse à tous les accros du Snowpark qui souhaitent découvrir de nouvelles sensations ou perfectionner leur niveau.",
        coverUrl: FreestyleIMG,
        disabled: true,
        visible: false,
        type: 'product',
        details: [
            {
                description: "Snowboard. Deux entraînements par semaine durant toute la saison d’hiver.",
                price: 800,
                discountHP: 300,
            },
            {
                description: "Freeski. Deux entraînements par semaine durant toute la saison d’hiver.",
                price: 800,
                discountHP: 300,
            }
        ],
    },
    {
        id: 2,
        name: "Speedies",
        description: "Pour les enfants entre 5 et 9 ans qui ont un niveau intermédiaire et souhaitent skier toute la saison, y compris pendant les vacances scolaires des cantons du VS, VD et GE.",
        coverUrl: PreCompetitionIMG,
        disabled: false,
        visible: true,
        type: 'product',
        details: [
            {
                description: "Groupe A: Trois entrainements par semaine, le mercredi après-midi, le samedi matin et le dimanche matin, plus la semaine de vacances scolaires",
                price: 700,
                discountHP: 100,
            },
            {
                description: "Groupe B: Entraînements journaliers durant la saison d’hiver.",
                price: 1800,
            }
        ],
    },
    {
        id: 3,
        name: "Pré-Compétition Plus",
        description: "Pour les enfants de 5 à 7 ans",
        coverUrl: PreCompetitionPlusIMG,
        disabled: true,
        visible: false,
        type: 'product',
        details: [
            {
                description: "Entraînements journaliers durant la saison d’hiver",
                price: 1800,
            }
        ],
    },
    {
        id: 6,
        name: "Pass 365",
        description: "Abonnements saisonniers 2021/2022",
        coverUrl: SkiFunIMG,
        disabled: true,
        visible: false,
        type: 'subscription',
        details: [
            {
                description: "Pass 365 Adulte",
                descriptionHtml: <>
                    <p><b>Pass 365 Adulte (né avant le 1er décembre 2005) :</b> CHF 365 + CHF 10 de frais administratifs, soit CHF 375 / abonnement</p>
                    <p><b>Pass 365 Enfant (né entre le 1er décembre 2005 et le 31 décembre 2011)* :</b> CHF 199 + CHF 10 de frais administratifs, soit CHF 209 / abonnement</p>
                    <p><b>Enfants de moins de 9 ans (nés après le 1er janvier 2012) :</b>  GRATUIT</p>
                    <p><i><b>*ATTENTION :</b> Conditions particulières pour les enfants domiciliés sur les communes de Crans-Montana, Lens et Icogne :<br />
                        Enfants GRATUITS jusqu'à 20 ans (nés après le 1er décembre 2003) domiciliés sur les communes de Crans-Montana et de Lens et d'Icogne<br />
                        Enfants GRATUITS jusqu'à 20 ans (nés après le 1er décembre 2001) domiciliés sur les communes d’ Icogne</i><br />
                    </p>
                    <Alert
                        style={{ width: 'calc(100% - 10px)' }}
                        message="Pass 365 Gratuit"
                        description="Les abonnements gratuits ne sont pas commandables depuis le shop. Pour l'obtention d'un abonnement gratuit veuillez contacter directement la CMA."
                        type="warning"
                    />
                </>,
                descriptionAdult: "Pass 365 Adulte",
                descriptionJunior: "Pass 365 Enfant",
                descriptionFree: "Pass 365 Gratuit",
                price: 375,
                priceJunior: 209
            },
        ],
    },
    {
        id: 8,
        name: "Pass ONE Ski-club",
        // description: "Abonnement saisonnier valable du 1er juillet 2023 au 30 juin 2024",
        description: "Offre clôturée!",
        coverUrl: SkiFunIMG,
        disabled: true,
        visible: false,
        type: 'subscription',
        details: [
            {
                description: "Pass ONE",
                descriptionHtml: <>
                    <p><b>« Pass ONE Adulte » (nés avant le 1er juillet 2002, 1er juillet 2002 inclus) :</b> CHF 429 + CHF 10 de frais administratifs, soit CHF 439 / abonnement</p>
                    <p><b>« Pass ONE Ado » (nés entre le 2 juillet 2002 et le 1er juillet 2007 inclus)* :</b> CHF 349 + CHF 10 de frais administratifs, soit CHF 359 / abonnement</p>
                    <p><b>« Pass ONE Enfant » (nés entre le 2 juillet 2007 et le 1er juillet 2014 inclus)* :</b> CHF 269 + CHF 10 de frais administratifs, soit CHF 279 / abonnement</p>
                    <p><b>Enfants nés après le 2 juillet 2014 :</b>  GRATUIT</p>
                    <p><i><b>*ATTENTION :</b> Conditions particulières pour les enfants domiciliés sur les communes de Crans-Montana, de Lens et d'Icogne :<br />
                        Enfants nés à partir du 2 juillet 2003 (2 juillet inclus) domiciliés sur les communes de Crans-Montana, de Lens et d'Icogne : GRATUIT (moins de 20 ans au 1er juillet 2023)</i><br />
                    </p>
                    <Alert
                        style={{ width: 'calc(100% - 10px)' }}
                        message="Pass ONE Gratuit"
                        description="Les abonnements gratuits ne sont pas commandables depuis le shop. Pour l'obtention d'un abonnement gratuit veuillez contacter directement la CMA."
                        type="warning"
                    />
                </>,
                descriptionAdult: "Pass ONE Adulte",
                descriptionTeenager: "Pass ONE Ado",
                descriptionJunior: "Pass ONE Enfant",
                descriptionFree: "Pass ONE Gratuit",
                price: 439,
                priceTeenager: 359,
                priceJunior: 279
            },
        ],
    },
    {
        id: 9,
        name: "Pass PARTENAIRE Ski-club",
        // description: "Abonnement saisonnier valable du 1er juillet 2023 au 30 juin 2024",
        description: "Abonnements saisonniers 2024/2025",
        coverUrl: SkiFunIMG,
        disabled: false,
        visible: false,
        type: 'subscription',
        details: [
            {
                description: "Pass PARTENAIRE",
                descriptionHtml: <>
                    <p>Offre exclusivement réservée aux membres du Ski Club Crans-Montana en ordre de cotisation pour la saison 2023/2024</p>
                    <p><b>« Pass PARTENAIRE Adulte » (nés avant le 30 mai 2003, 30 mai 2003 inclus) :</b> CHF 429 + CHF 10 de frais administratifs, soit CHF 439 / abonnement</p>
                    <p><b>« Pass PARTENAIRE Ado » (nés entre le 31 mai 2003 et le 30 mai 2008 inclus)* :</b> CHF 349 + CHF 10 de frais administratifs, soit CHF 359 / abonnement</p>
                    <p><b>« Pass PARTENAIRE Enfant » (nés entre le 31 mai 2008 et le 30 mai 2015 inclus)* :</b> CHF 269 + CHF 10 de frais administratifs, soit CHF 279 / abonnement</p>
                    <p><b>Enfants nés après le 31 mai 2015 :</b>  GRATUIT</p>
                    <p><i><b>*ATTENTION :</b> Conditions particulières pour les enfants domiciliés sur les communes de Crans-Montana, de Lens et d'Icogne :<br />
                        Enfants nés à partir du 31 mai 2004 (31 mai inclus) domiciliés sur les communes de Crans-Montana, de Lens et d'Icogne : GRATUIT (moins de 20 ans au 30 mai 2024)</i><br />
                    </p>
                    <Alert
                        style={{ width: 'calc(100% - 10px)' }}
                        message="Pass PARTENAIRE Gratuit"
                        description="Les abonnements gratuits ne sont pas commandables depuis le shop. Pour l'obtention d'un abonnement gratuit veuillez contacter directement la CMA."
                        type="warning"
                    />
                </>,
                descriptionAdult: "Pass PARTENAIRE Adulte",
                descriptionTeenager: "Pass PARTENAIRE Ado",
                descriptionJunior: "Pass PARTENAIRE Enfant",
                descriptionFree: "Pass PARTENAIRE Gratuit",
                price: 439,
                priceTeenager: 359,
                priceJunior: 279
            },
        ],
    },
];

type Props = IProps & WrappedComponentProps & RouterProps;

class Registration extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            countries: undefined,
            selectedCountry: undefined,
            activeKey: "1",
            participants: [],
            participantsTabDisabled: false,
            cartTabDisabled: false,
            products: productsAvailable,
            cart: undefined,
            must1: false,
            must2: false,
            productSelectedNext: false,
        };
    }



    getCountries = (lang: string) => {
        Network.getCountriesList(lang)
            .then(data => {
                this.setState({ countries: data.data });
                window.onbeforeunload = () => true;

                Modal.info({
                    centered: true,
                    style: {},
                    width: 1040,
                    title: 'Bienvenue sur le Shop du Ski Club Crans-Montana',
                    content: (
                        <div>
                            <p>Voici quelques conseils pour utiliser correctement notre plateforme:</p>
                            <ul style={{ paddingLeft: '25px' }}>
                                <li>Veillez à ne pas actualiser la page lorsque vous passez une commande afin de ne pas perdre les données que vous auriez déjà introduites.</li>
                                <li>Veuillez remplir un formulaire pour chaque membre de votre famille pour lequel vous commandez un pass.</li>
                                <li>Utilisez le bouton "Payer" au moment du "Checkout" pour effectuer le paiement.</li>
                                <li>À la fin du paiement, veuillez patienter jusqu’à ce que le paiement soit confirmé.</li>
                            </ul>
                        </div>
                    ),
                    onOk() { },
                    okText: "J'ai lu"
                });
            })
            .catch(error => {
                console.error("Countries error : ", error);
                let errorParsed = "";
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    errorParsed += (error.response.status + " - " + error.message);

                    console.error("Countries error : ", error.response.data);
                    console.error("Countries error : ", error.response.status);
                    console.error("Countries error : ", error.response.headers);
                } else if (error.request) {
                    // The request was made but no response was received
                    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                    // http.ClientRequest in node.js
                    errorParsed += error.request;
                    console.error("Countries error : ", error.request);
                } else {
                    // Something happened in setting up the request that triggered an Error
                    errorParsed += error.message;
                    console.error("Countries error : ", error.message);
                }
                Modal.error({
                    centered: true,
                    style: {},
                    width: 700,
                    title: 'Chargement des pays',
                    content: (
                        <>
                            <p>Erreur lors du chargement de la liste des pays, veuillez actualiser la page.</p>
                            <p>Si l'erreur persiste utilisez un autre navigateur / appareil.</p><br />
                            <p>Code error : {errorParsed}</p>
                        </>
                    ),
                    onOk() {
                        window.location.reload();
                    },
                    okText: "Actualiser"
                });
            });
    };

    componentDidMount() {
        document.title = "Ski Club Crans-Montana";
        localStorage.clear();
        // this.checkTabsAvailable();
        let lang = this.props.router.params.lang;
        this.getCountries(lang);
        // Network.getCantonList(lang).then(data => this.setState({ canton: data.data }));

    }

    componentDidUpdate() {
        this.checkTabsAvailable();
    }

    componentWillUnmount() {
        window.onbeforeunload = null;
    }

    onSelectCountry = (id: number) => {
        const selectedCountry = this.state.countries?.find(c => c.id === id);
        this.setState({ selectedCountry });
    };

    checkTabsAvailable = () => {
        const { customer, participantsTabDisabled, cartTabDisabled } = this.state;
        if (customer && customer.address && customer.city && customer.email && customer.firstName && customer.lastName && customer.npa && customer.phone && this.state.selectedCountry && ((this.state.productSelected?.type === "subscription" && this.state.must1 && this.state.must2) || this.state.productSelected?.type !== "subscription")) {
            participantsTabDisabled && this.setState({ participantsTabDisabled: false });
        } else {
            !participantsTabDisabled && this.setState({ participantsTabDisabled: true });
        }

        if (!participantsTabDisabled && this.participantsValidated()) {
            cartTabDisabled && this.setState({ cartTabDisabled: false });
        } else {
            !cartTabDisabled && this.setState({ cartTabDisabled: true });
        }
    };

    participantsValidated = () => {
        const { participants } = this.state;
        let participantsValidated = true;

        if (participants && participants.length > 0) {
            participants.map((participant) => {

                if (!participant.birthday || !participant.firstName || participant.gender == null || !participant.lastName) {
                    participantsValidated = false;
                }
                return undefined;
            });
        } else {
            participantsValidated = false;
        }
        return participantsValidated;
    };

    addParticipant = (participant: Participant) => {
        let { participants } = this.state;
        participants.push(participant);
        this.setState({ participants });
    };

    editParticipants = (id: number, participant: Participant) => {
        this.setState({ participantToEdit: participant, participantToEditId: id });
    };

    cancelEditParticipant = () => {
        this.setState({ participantToEditId: undefined, participantToEdit: undefined });
    };

    saveEditParticipant = (id: number, participant: Participant) => {
        let { participants } = this.state;
        participants = participants.map((p, idx) => {
            return idx === id ? participant : p;
        });
        this.setState({ participants: participants, participantToEditId: undefined, participantToEdit: undefined });
    };

    deleteParticipant = (id: number) => {
        let { participants } = this.state;
        participants.splice(id, 1);
        this.setState({ participants });
    };

    deleteParticipants = () => {
        this.setState({ participants: [] });
    };

    onClickOnlinePay = () => {
        window.onbeforeunload = null;
        const { productSelected, customer, participants, detailSelected, detailLevelSelected, invoiceHtml } = this.state;
        const { formatMessage } = this.props.intl;
        let lang = this.props.router.params.lang;
        const hostname = window.location.hostname;
        const port = window.location.port;
        const RETURNSUCCESS = "https://" + hostname + ":" + port + "/" + formatMessage({ id: 'lang' }) + "/pay_success";
        const RETURNABORDT = "https://" + hostname + ":" + port + "/" + formatMessage({ id: 'lang' }) + "/registration";
        const RETURNERROR = "https://" + hostname + ":" + port + "/" + formatMessage({ id: 'lang' }) + "/pay_error";
        let participantsString: string[] = [];
        participants.forEach((participant) => {
            participantsString.push(participant.firstName + "#" + participant.lastName + "#" + moment(participant.birthday).toISOString() + "#" + participant.gender + "#" + participant.avs + "#" + participant.sizeJacket);
        });
        let productString: string = productSelected?.name + "#" + detailSelected?.description + "#" + detailSelected?.price + "#" + detailSelected?.discountHP + "#" + detailSelected?.option + "#" + detailSelected?.optionPrice + "#" + detailLevelSelected;
        if (productSelected && customer && customer.firstName && customer.lastName && customer.phone && customer.address && customer.npa && customer.city && customer.email && detailSelected) {

            let participantsAdult: Participant[] = [];
            let participantsTeenager: Participant[] = [];
            let participantsJunior: Participant[] = [];
            // let participantsJuniorFree: Participant[] = [];
            let finalPrice: number = 0;
            if (productSelected?.type === 'subscription') {
                if (customer.domiciledAtCMAndL !== undefined && customer.domiciledAtCMAndL) {
                    participantsAdult = participants.filter(p => p.birthday && p.birthday.isSameOrBefore(moment("01/07/2003", 'DD/MM/YYYY'), "days"));
                    participantsTeenager = participants.filter(p => p.birthday && p.birthday.isBetween(moment("02/07/2003", 'DD/MM/YYYY'), moment("01/07/2004", 'DD/MM/YYYY'), "days", "[]"));
                } else if (customer.domiciledAtI !== undefined && customer.domiciledAtI) {
                    participantsAdult = participants.filter(p => p.birthday && p.birthday.isSameOrBefore(moment("01/07/2003", 'DD/MM/YYYY'), "days"));
                    participantsTeenager = participants.filter(p => p.birthday && p.birthday.isBetween(moment("02/07/2003", 'DD/MM/YYYY'), moment("01/07/2004", 'DD/MM/YYYY'), "days", "[]"));
                } else {
                    participantsAdult = participants.filter(p => p.birthday && p.birthday.isSameOrBefore(moment("01/07/2003", 'DD/MM/YYYY'), "days"));
                    participantsTeenager = participants.filter(p => p.birthday && p.birthday.isBetween(moment("02/07/2003", 'DD/MM/YYYY'), moment("01/07/2008", 'DD/MM/YYYY'), "days", "[]"));
                    participantsJunior = participants.filter(p => p.birthday && p.birthday.isBetween(moment("02/07/2008", 'DD/MM/YYYY'), moment("01/07/2015", 'DD/MM/YYYY'), "days", "[]"));
                }
                finalPrice = participantsAdult.length * detailSelected.price + participantsTeenager.length * (detailSelected.priceTeenager ?? 0) + participantsJunior.length * (detailSelected.priceJunior ?? 0);
            } else {
                finalPrice = (customer.alreadyPaidFee ? 0 : 80) + (detailSelected.price ? participants.length * detailSelected.price : 0) + (detailSelected.optionPrice ? participants.length * detailSelected.optionPrice : 0) - ((detailSelected.discountHP && customer.domiciledAtHP) ? participants.length * detailSelected.discountHP : 0);
            }

            let infoValues: RegistrationBobyRequest = {
                companyId: SKICLUBCRANSMONTANAID,
                email: customer.email,
                firstName: customer.firstName,
                lastName: customer.lastName,
                language: lang,
                currency: CURRENCY,
                amount: finalPrice * 100,
                typeQuery: "initialize",
                returnSuccess: RETURNSUCCESS,
                returnAbort: RETURNABORDT,
                returnError: RETURNERROR,
                accentColor: ACCENTCOLOR,
                backgroundColor: BACKGROUNDCOLOR,
                billingDescriptor: SKICLUBCRANSMONTANANAME,
                description: productSelected.name,
                paymentMethods: ["credit_card"],

                fieldsValues: {
                    invoiceHtml: invoiceHtml ? invoiceHtml : "-",
                    phone: customer.phone,
                    address: customer.address,
                    postcode: customer.npa,
                    city: customer.city,
                    country: this.state.selectedCountry ? this.state.selectedCountry.id : 0,
                    totalPrice: finalPrice,
                    domiciledAtHP: customer.domiciledAtHP ? customer.domiciledAtHP : false,
                    domiciledAtCMAndL: customer.domiciledAtCMAndL ? customer.domiciledAtCMAndL : false,
                    domiciledAtI: customer.domiciledAtI ? customer.domiciledAtI : false,
                    lastYear: customer.lastYear ? customer.lastYear : false,
                    alreadyPaidFee: customer.alreadyPaidFee ? customer.alreadyPaidFee : false,
                    photo: customer.photo ? customer.photo : false,
                    participants: participantsString,
                    product: productString,
                }
            };

            let payyoCapture: PayyoCaptureBobyRequest = {
                typeQuery: "capture",
                lang: infoValues.language,
                transactionId: "-",
                companyId: '' + infoValues.companyId,
                email: infoValues.email,
                firstName: infoValues.firstName,
                lastName: infoValues.lastName,

                fieldsValues: {
                    transaction_id: '-',
                    invoiceHtml: infoValues.fieldsValues.invoiceHtml,
                    phone: infoValues.fieldsValues.phone,
                    address: infoValues.fieldsValues.address,
                    postcode: infoValues.fieldsValues.postcode,
                    city: infoValues.fieldsValues.city,
                    country: infoValues.fieldsValues.country,
                    total_price: infoValues.fieldsValues.totalPrice,
                    domiciled_at_hp: infoValues.fieldsValues.domiciledAtHP,
                    domiciled_at_cm_and_l: infoValues.fieldsValues.domiciledAtCMAndL,
                    domiciled_at_i: infoValues.fieldsValues.domiciledAtI,
                    last_year: infoValues.fieldsValues.lastYear,
                    already_paid_fee: infoValues.fieldsValues.alreadyPaidFee,
                    photo: customer.photo ? customer.photo : false,
                    participants: infoValues.fieldsValues.participants,
                    product: infoValues.fieldsValues.product,

                }
            };

            localStorage.setItem('payyoValues', JSON.stringify(payyoCapture));


            Network.register(infoValues).then(data => {
                if (data.data.result?.checkout_url) {
                    window.location.href = data.data.result.checkout_url;
                } else {
                    if (data.data.error === "purchases_limit") {
                        Modal.warning({
                            style: {},
                            width: 700,
                            title: 'Seuil de disponibilité atteint',
                            content: (
                                <div>
                                    <p>La limite d'abonnements disponibles en ligne pour cette saison a été atteinte.</p>
                                    <p>Veuillez nous contacter par téléphone.</p>
                                </div>
                            ),
                        });
                    } else if (data.data.error === "purchases_deactivated") {
                        Modal.warning({
                            style: {},
                            width: 700,
                            title: 'Vente en ligne désactivée',
                            content: (
                                <div>
                                    <p>Le système de vente en ligne est actuellement désactivé.</p>
                                    <p>Veuillez nous contacter par téléphone.</p>
                                </div>
                            ),
                        });
                    } else {
                        Modal.warning({
                            style: {},
                            width: 700,
                            title: 'Problème lors de l\'initialisation de paiement',
                            content: (
                                <div>
                                    <p>Système de paiement temporairement inopérationnel.</p>
                                    <p>Veuillez réessayer plus tard.</p>
                                </div>
                            ),
                        });
                    }
                }
            });
        } else {
            console.log(">>>>>>>>>>>>>>", "error");
        }
    };

    onInputChange = (inputName: string, value: string) => {
        let { customer } = this.state;
        const key: (keyof Customer) = inputName as keyof Customer;
        !customer && (customer = {});

        customer[key] = value as any;
        this.setState({ customer });
    };

    onCheckboxChange = (inputName: string, value: boolean) => {
        let { customer } = this.state;
        const key: (keyof Customer) = inputName as keyof Customer;
        !customer && (customer = {});

        customer[key] = value as any;
        // Only 1 commune
        if (inputName === "domiciledAtCMAndL" && value === true) {
            customer.domiciledAtI = false;
        } else if (inputName === "domiciledAtI" && value === true) {
            customer.domiciledAtCMAndL = false;
        }
        this.setState({ customer });
    };

    handleChange = (activeKey: string) => {
        this.setState({ activeKey });
    };

    selectProductDetail = (detail: ProductDetail) => {
        this.setState({ detailSelected: detail });
    };

    selectProductDetailLevel = (level: string) => {
        this.setState({ detailLevelSelected: level });
    };

    selectProduct = (product: Product) => {
        let { detailSelected, detailLevelSelected } = this.state;
        detailSelected = {
            description: product.details[0].description,
            descriptionAdult: product.details[0].descriptionAdult,
            descriptionTeenager: product.details[0].descriptionTeenager,
            descriptionJunior: product.details[0].descriptionJunior,
            descriptionFree: product.details[0].descriptionFree,
            price: product.details[0].price,
            priceTeenager: product.details[0].priceTeenager,
            priceJunior: product.details[0].priceJunior,
            discountHP: product.details[0].discountHP,
            option: undefined,
            optionPrice: undefined,
        };


        if (product.details[0].level && product.details[0].level.length > 0) {
            detailLevelSelected = product.details[0].level[0];
        } else {
            detailLevelSelected = undefined;
        }

        if (product.id === 1) {
            this.setState({ productSelected: product, detailSelected, detailLevelSelected, participants: [] });
        } else {
            this.setState({ productSelected: product, detailSelected, detailLevelSelected });
        }
    };

    invoiceToHtml = (content: JSX.Element) => {
        this.state.invoiceHtml !== ReactDOMServer.renderToStaticMarkup(content) && this.setState({ invoiceHtml: ReactDOMServer.renderToStaticMarkup(content) });
    };

    changeTab = (tabIndex: string) => {
        this.setState({ activeKey: tabIndex });
    };

    render() {
        const { formatMessage } = this.props.intl;
        // const { Text } = Typography;
        const { TabPane } = Tabs;
        const { participantsTabDisabled, cartTabDisabled, productSelected, productSelectedNext, customer, participants, detailSelected, detailLevelSelected, participantToEdit, participantToEditId } = this.state;
        const search = this.props.router.location.search; // could be '?foo=bar'
        const params = new URLSearchParams(search);
        const queryProductId = params.get("product");

        let nextConfirmProduct = <>
            <p>Assurez-vous que les informations du cours sont correctes :</p>
            <p><b>Cours : </b>{detailSelected?.description}</p>
            {
                detailLevelSelected && <p><b>Niveau : </b>{detailLevelSelected}</p>
            }
            {
                detailSelected?.option && <p><b>Option : </b>{detailSelected.option}</p>
            }
        </>;
        return (
            <div>
                <div className="__background-custom">
                </div>
                <div className="__content-custom">
                    <Row>
                        <Col span={24}>
                            <Title level={1}>
                                {formatMessage({ id: "registration.title" })}
                            </Title>
                            {/* <Title level={2}>
                                {formatMessage({ id: "registration.subtitle" })}
                                <Tooltip placement="right" title={formatMessage({ id: "registration.explain" })}>
                                    <InfoCircleFilled style={{ cursor: 'pointer', fontSize: '80%', verticalAlign: 'middle', marginBottom: '5px', marginLeft: '10px' }} />
                                </Tooltip>
                            </Title> */}
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Tabs

                                onChange={this.handleChange}
                                activeKey={this.state.activeKey}
                            >
                                <TabPane
                                    tab={
                                        <span>
                                            <ShoppingOutlined />
                                            Produits
                                        </span>
                                    }
                                    disabled={false}
                                    key="1">
                                    <div className="__registration_tab">
                                        <ProductsTab selectProductDetailLevel={this.selectProductDetailLevel} queryProductId={queryProductId} selectProductDetail={this.selectProductDetail} selectProduct={this.selectProduct} productSelected={this.state.productSelected} products={this.state.products} />
                                        <Row style={{ marginTop: '20px' }}>
                                            <Col xs={24} sm={12} md={12} lg={12} xl={12} style={{ textAlign: 'left' }}>
                                                {
                                                    productSelected &&
                                                    <Button onClick={() => {
                                                        const { productSelected } = this.state;
                                                        if (productSelected?.id === 1) {
                                                            this.setState({ productSelected: undefined, productSelectedNext: false, participants: [] });
                                                        } else {
                                                            this.setState({ productSelected: undefined, productSelectedNext: false });
                                                        }
                                                    }} className="form-button" type="primary" >{formatMessage({ id: 'product.allProducts' })}</Button>
                                                }
                                            </Col>
                                            <Col xs={24} sm={12} md={12} lg={12} xl={12} style={{ textAlign: 'right' }}>
                                                {
                                                    productSelected === undefined ?
                                                        <Button disabled className="form-button" type="primary" >{formatMessage({ id: 'registration.next' })}</Button>
                                                        :
                                                        <Popconfirm
                                                            title={nextConfirmProduct}
                                                            okText="Valider"
                                                            cancelText="Modifier"
                                                            onConfirm={() => { this.setState({ activeKey: "2", productSelectedNext: true }); }}
                                                        >
                                                            <Button className="form-button" type="primary" >{formatMessage({ id: 'registration.next' })}</Button>
                                                        </Popconfirm>
                                                }
                                            </Col>
                                        </Row>
                                    </div>
                                </TabPane>
                                <TabPane
                                    tab={
                                        <span>
                                            <UserOutlined />
                                            Données personnelles
                                        </span>
                                    }
                                    key="2"
                                    disabled={productSelected === undefined || !productSelectedNext}>
                                    <div className="__registration_tab">
                                        <Row gutter={[8, 8]}>
                                            <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                                                <Input value={customer?.firstName} onChange={(e) => this.onInputChange("firstName", e.target.value)} className="form-input" placeholder={formatMessage({ id: 'registration.firstName' })} />
                                            </Col>
                                            <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                                                <Input value={customer?.lastName} onChange={(e) => this.onInputChange("lastName", e.target.value)} className="form-input" placeholder={formatMessage({ id: 'registration.lastName' })} />
                                            </Col>
                                            <Col xs={24} sm={12} md={6} lg={6} xl={6}>
                                                <Input value={customer?.address} onChange={(e) => this.onInputChange("address", e.target.value)} className="form-input" placeholder={formatMessage({ id: 'registration.address' })} />
                                            </Col>
                                            <Col xs={24} sm={12} md={6} lg={6} xl={6}>
                                                <Input value={customer?.npa} onChange={(e) => this.onInputChange("npa", e.target.value)} className="form-input" placeholder={formatMessage({ id: 'registration.npa' })} />
                                            </Col>
                                            <Col xs={24} sm={12} md={6} lg={6} xl={6}>
                                                <Input value={customer?.city} onChange={(e) => this.onInputChange("city", e.target.value)} className="form-input" placeholder={formatMessage({ id: 'registration.city' })} />
                                            </Col>
                                            <Col xs={24} sm={12} md={6} lg={6} xl={6}>
                                                <Select
                                                    placeholder={formatMessage({ id: 'registration.company.country' })}
                                                    allowClear
                                                    showSearch
                                                    filterOption={(input, option) => (option?.label as string).toLowerCase().startsWith(input.toLowerCase())}
                                                    onChange={this.onSelectCountry}
                                                    className="form-input form-input-select"
                                                    options={this.state.countries?.map(c=>({label: c.translations[0].name, value: c.id}))}
                                                />
                                            </Col>
                                            <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                                                <Input value={customer?.email} onChange={(e) => this.onInputChange("email", e.target.value)} className="form-input" placeholder={formatMessage({ id: 'registration.email' })} />
                                            </Col>
                                            <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                                                <Input value={customer?.phone} onChange={(e) => this.onInputChange("phone", e.target.value)} className="form-input" placeholder={formatMessage({ id: 'registration.phone' })} />
                                            </Col>

                                            {
                                                productSelected?.type !== 'subscription' ?
                                                    <>
                                                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                                            <Checkbox onChange={(e) => this.onCheckboxChange("lastYear", e.target.checked)} checked={customer?.lastYear}>{formatMessage({ id: 'registration.lastYear' })}</Checkbox>
                                                        </Col>
                                                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                                            <Checkbox onChange={(e) => this.onCheckboxChange("domiciledAtHP", e.target.checked)} checked={customer?.domiciledAtHP}>{formatMessage({ id: 'registration.domiciledAtHP' })}</Checkbox>
                                                        </Col>
                                                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                                            <Checkbox onChange={(e) => this.onCheckboxChange("alreadyPaidFee", e.target.checked)} checked={customer?.alreadyPaidFee}>{formatMessage({ id: 'registration.alreadyPaidFee' })}</Checkbox>
                                                        </Col>
                                                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                                            <Checkbox onChange={(e) => this.onCheckboxChange("photo", e.target.checked)} checked={customer?.photo}>{formatMessage({ id: 'registration.photo' })}</Checkbox>
                                                        </Col>
                                                    </>
                                                    :
                                                    <>
                                                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                                            <Checkbox onChange={(e) => this.onCheckboxChange("domiciledAtCMAndL", e.target.checked)} checked={customer?.domiciledAtCMAndL}>{formatMessage({ id: 'registration.domiciledAtCMAndL' })}</Checkbox>
                                                        </Col>
                                                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                                            <Checkbox onChange={(e) => this.onCheckboxChange("domiciledAtI", e.target.checked)} checked={customer?.domiciledAtI}>{formatMessage({ id: 'registration.domiciledAtI' })}</Checkbox>
                                                        </Col>
                                                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                                            <Checkbox style={{ fontWeight: 'bold' }} onChange={(e) => this.setState({ must1: e.target.checked })} checked={this.state.must1}>{formatMessage({ id: 'registration.cotisation' })}</Checkbox>
                                                        </Col>
                                                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                                            <Checkbox style={{ fontWeight: 'bold' }} onChange={(e) => this.setState({ must2: e.target.checked })} checked={this.state.must2}>{formatMessage({ id: 'registration.cma' })}</Checkbox>
                                                        </Col>
                                                        <Col xs={24} sm={24} md={24} lg={24} xl={24}><b><i>* obligatoire</i></b></Col>
                                                    </>
                                            }
                                        </Row>
                                        <Row style={{ marginTop: '20px' }}>
                                            <Col xs={24} sm={12} md={12} lg={12} xl={12} style={{ textAlign: 'left' }}>
                                                <Button onClick={() => { this.setState({ activeKey: "1" }); }} className="form-button" type="primary" >{formatMessage({ id: 'product.selected' })}</Button>
                                            </Col>
                                            <Col xs={24} sm={12} md={12} lg={12} xl={12} style={{ textAlign: 'right' }}>
                                                <Button disabled={productSelected === undefined || participantsTabDisabled} onClick={() => { this.setState({ activeKey: "3" }); }} className="form-button" type="primary" >{formatMessage({ id: 'participant.next' })}</Button>
                                            </Col>
                                        </Row>
                                    </div>
                                </TabPane>
                                <TabPane
                                    tab={
                                        <span>
                                            <UsergroupAddOutlined />
                                            Données du participant
                                        </span>
                                    }
                                    key="3"
                                    disabled={productSelected === undefined || participantsTabDisabled}>
                                    <div className="__registration_tab">
                                        <ParticipantsTab
                                            detailSelected={detailSelected}
                                            productSelected={productSelected}
                                            customer={customer}
                                            participantToEdit={participantToEdit}
                                            participantToEditId={participantToEditId}
                                            saveEditParticipant={this.saveEditParticipant}
                                            editParticipants={this.editParticipants}
                                            cancelEditParticipant={this.cancelEditParticipant}
                                            deleteParticipant={this.deleteParticipant}
                                            countries={this.state.countries}
                                            goToTab={this.handleChange}
                                            addParticipant={this.addParticipant}
                                            participants={this.state.participants} />
                                        <Row style={{ marginTop: '20px' }}>
                                            <Col xs={24} sm={12} md={12} lg={12} xl={12} style={{ textAlign: 'left' }}>
                                                <Button onClick={() => { this.setState({ activeKey: "2" }); }} className="form-button" type="primary" >{formatMessage({ id: 'registration.previous' })}</Button>
                                            </Col>
                                            <Col xs={24} sm={12} md={12} lg={12} xl={12} style={{ textAlign: 'right' }}>
                                                <Button disabled={productSelected === undefined || participantsTabDisabled || cartTabDisabled} onClick={() => { this.setState({ activeKey: "4" }); }} className="form-button" type="primary" >{formatMessage({ id: 'cart.go' })}</Button>
                                            </Col>
                                        </Row>
                                    </div>
                                </TabPane>
                                <TabPane
                                    tab={
                                        <span>
                                            <SafetyOutlined />
                                            {formatMessage({ id: 'cart.title' })}
                                        </span>
                                    }
                                    key="4"
                                    disabled={productSelected === undefined || participantsTabDisabled || cartTabDisabled}>
                                    <div className="__registration_tab">
                                        {
                                            productSelected?.type === "subscription" &&
                                            <Alert style={{ marginBottom: '10px' }} message="Les abonnements gratuits ne sont pas commandables depuis le shop. Pour l'obtention d'un abonnement gratuit veuillez contacter directement la CMA." type="warning" showIcon />
                                        }
                                        <Alert style={{ marginBottom: '20px' }} message={"Veuillez contrôler vos données et ensuite cliquez sur le bouton \"Payer\" en bas de cette page pour procéder au paiement."} type="info" showIcon />
                                        <CartTab
                                            changeTab={this.changeTab}
                                            invoiceToHtml={this.invoiceToHtml}
                                            participants={participants}
                                            customer={customer}
                                            productSelected={productSelected}
                                            detailSelected={detailSelected}
                                            countries={this.state.countries}
                                            detailLevelSelected={detailLevelSelected}/>
                                        <Row style={{ marginTop: '20px' }}>
                                            <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: 'center' }}>
                                                <Popconfirm
                                                    title={
                                                        productSelected?.type === "subscription" ?
                                                            <>
                                                                J'ai pris connaissance des conditions de l'offre qui m'ont été transmises par le ski club Crans-montana et je les accepte dans leur intégralité.<br />
                                                                Je décharge le ski club Crans-Montana de toute responsabilité en cas d'erreur du passage de ma commande Pass PARTENAIRE. Je comprends et accepte qu'aucune modification ni remboursement n'est possible après le passage de la commande.<br />
                                                                Le ski club Crans-Montana se réserve le droit d'annuler unilatéralement tout achat fait via le site par des personnes ne remplissant pas les conditions de cette offre.  Dans ce cas, les personnes concernées seront averties, le montant des abos (hors frais administratifs) sera remboursé mais aucune autre compensation ne sera faite.
                                                            </>
                                                            :
                                                            "Voulez-vous procéder au paiement? Cette action est irréversible."
                                                    }
                                                    onConfirm={this.onClickOnlinePay}
                                                    okText="Accepter"
                                                    cancelText="Annuler"
                                                >
                                                    <Button disabled={productSelected === undefined || participantsTabDisabled || cartTabDisabled} className="form-button form-button-pay" type="primary" >{formatMessage({ id: 'cart.pay' })}</Button>
                                                </Popconfirm>
                                            </Col>
                                        </Row>
                                    </div>
                                </TabPane>
                            </Tabs>
                        </Col>
                    </Row>
                </div>

                {/* <PaymentModal
                    open={!!this.state.values}
                    isSwiss={this.state.selectedCountry?.alpha2Code === "CH"}
                    onCancel={() => this.setState({ values: undefined })}
                    onClickOnlinePay={this.onClickOnlinePay}
                    onClickBankTransferPayed={this.onClickBankTransferPayed} /> */}
            </div >
        );
    }
}

export default withRouter(injectIntl(Registration));